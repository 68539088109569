import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
import { Divider } from 'fannypack';
import ColouredText from '../../../components/worksafe-vic/ColouredText';
import Highlight from '../../../components/worksafe-vic/Highlight';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "claims-processing",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#claims-processing",
        "aria-label": "claims processing permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Claims processing`}</h1>
    <p>{`The Claims processing section consists of the following sections:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#claims-processing-flows"
        }}>{`Claims processing flows`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#claim-structure"
        }}>{`Claim structure`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#processing-and-settlement-periods-and-payment-of-invoices"
        }}>{`Processing and Settlement periods and Payment of invoices`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#remittance-reports"
        }}>{`Reporting, statements and receipts`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#remittance-reports"
        }}>{`Remittance reports`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#eligibility-checks-and-quotes"
        }}>{`Eligibility checks and quotes`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#supported-providers-and-patients"
        }}>{`Supported Providers and Patients `}</a></li>
    </ul>
    <h3 {...{
      "id": "claims-processing-flows",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#claims-processing-flows",
        "aria-label": "claims processing flows permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Claims processing flows`}</h3>
    <p><img parentName="p" {...{
        "src": "/images/Comcare-claim-flow-diagram.png",
        "alt": null
      }}></img></p>
    <p>{`Comcare claims - also called invoices - will have both synchronous and asynchronous outcomes. Approximately 75% of claims are adjudicated in real-time with a synchronous response provided within 30 seconds. The remainder of claims are asynchronous, with adjudication responses ranging from hours to typically two business days from submission. Partners will need to accommodate for asynchronous responses which can be pushed from Tyro Health via webhooks or periodically polled by partners via an invoice status API.`}</p>
    <p>{`Once a claim is submitted, the status can change as follows:`}</p>
    <ul>
      <li parentName="ul">
        <Highlight mdxType="Highlight">Outstanding</Highlight>: claim is under review by Comcare
      </li>
      <li parentName="ul">
        <Highlight mdxType="Highlight">Approved</Highlight>: claim is approved for settlement
      </li>
      <li parentName="ul">
        <Highlight mdxType="Highlight">Declined</Highlight>: claim was declined and will not be paid
      </li>
      <li parentName="ul">
        <Highlight mdxType="Highlight">Cancelled</Highlight>: claim was canceled by the provider prior to settlement
      </li>
      <li parentName="ul">
        <Highlight mdxType="Highlight">Completed</Highlight>: approved claim paid/settled to provider
      </li>
    </ul>
    <p>{`If multiple service items are claimed and one item is rejected the whole invoice will be marked as Declined. `}</p>
    <p>{`As Comcare has a pharmacy review panel all pharmacy related claims will go into the under review status when submitted. `}</p>
    <p>{`Once a claim is approved, payment is issued the next business day. Comcare utilises a 5pm AET end of day cutoff. Claims approved after 5pm AET will be included in the following business day settlement period. If multiple claims are settled for the same provider, a single aggregate payment will be made. A remittance report is issued to providers on settlement which details each claim paid. If a provider has multiple claims - even from different insurers - or card payments issued by Tyro Health in the same settlement period, those claims will be included in the same aggregated settlement. `}</p>
    <Divider marginTop="major-4" marginBottom="major-4" mdxType="Divider" />
    <h4 {...{
      "id": "claim-structure",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#claim-structure",
        "aria-label": "claim structure permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Claim structure`}</h4>
    <p>{`In Comcare, patients are referred to as “employees”. However, the details required to claim are similar to other compensatory funders. To issue a successful claim, the following details are required in each invoice:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Provider, profession and practice Location`}</strong>{`: Usually set with a single provider number attribute. These details must be registered with Tyro Health in advance of a claim. Only one provider can claim per invoice. `}<br />{` `}</li>
      <li parentName="ul"><strong parentName="li">{`Patient details`}</strong>{`: including first and last name, date of birth and comcare-issued claim number.`}</li>
      <li parentName="ul"><strong parentName="li">{`Service items`}</strong>{`: including service date, item number and fees are required. Optional attributes can include quantity, description and other item attributes. Multiple service items from multiple service dates can be claimed per invoice. `}</li>
    </ul>
    <p>{`Comcare supports invoice level service notes and attachments. Providers can attach up to four documents with the limit of 25MB per document.Documents  must be uploaded from within the Tryo Health SDK. `}</p>
    <p>{`The list of supported Comcare document types are as follows:`}</p>
    <ul>
      <li parentName="ul">{`Claim Document`}</li>
      <li parentName="ul">{`Comcare Aids and Appliances Claim Form`}</li>
      <li parentName="ul">{`Comcare Hearing Aid Request Form`}</li>
      <li parentName="ul">{`Comcare Home Help or Attendant Care Form`}</li>
      <li parentName="ul">{`Comcare Rehabilitation Form`}</li>
      <li parentName="ul">{`Comcare Review Of Medication Form`}</li>
      <li parentName="ul">{`Correspondence - General`}</li>
      <li parentName="ul">{`Home Help or Attendant Care Provider Report`}</li>
      <li parentName="ul">{`Correspondence - Medical Provider - General`}</li>
      <li parentName="ul">{`Imaging or Investigation Results`}</li>
      <li parentName="ul">{`Rehabilitation Provider Report`}</li>
      <li parentName="ul">{`Medical Certificate`}</li>
      <li parentName="ul">{`Certificate of Capacity`}</li>
      <li parentName="ul">{`Pharmaceutical Script (required on all private pharmacy items).`}</li>
    </ul>
    <p>{`Tyro health recommends that you keep a copy of all the documents you attach as you will not be able to access these once the claim has been submitted. `}</p>
    <Divider marginTop="major-4" marginBottom="major-4" mdxType="Divider" />
    <h3 {...{
      "id": "supported-providers-and-patients",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#supported-providers-and-patients",
        "aria-label": "supported providers and patients permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Supported providers and patients`}</h3>
    <h5 {...{
      "id": "professions-and-geographies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#professions-and-geographies",
        "aria-label": "professions and geographies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Professions and geographies`}</h5>
    <p>{`Comcare currently supports electronic claims from:`}</p>
    <ul>
      <li parentName="ul">{`Aboriginal and Torres Strait Islander Health Practice `}</li>
      <li parentName="ul">{`Acupuncture `}</li>
      <li parentName="ul"><strong parentName="li">{`Ambulance (support coming soon)`}</strong></li>
      <li parentName="ul">{`Audiology- Chinese Medicine `}</li>
      <li parentName="ul">{`Chiropractic `}</li>
      <li parentName="ul">{`Counselling `}</li>
      <li parentName="ul">{`Dental- Diagnostic Imaging `}</li>
      <li parentName="ul">{`Dietetics - Exercise Physiology`}</li>
      <li parentName="ul">{`General Health Equipment `}</li>
      <li parentName="ul">{`General Practice `}</li>
      <li parentName="ul">{`Genetic Counselling`}</li>
      <li parentName="ul">{`Home Care service and Aids `}</li>
      <li parentName="ul">{`Hospital - Mental Health `}</li>
      <li parentName="ul">{`Myotherapy`}</li>
      <li parentName="ul">{`Naturopathy`}</li>
      <li parentName="ul">{`Nursing and Midwifery`}</li>
      <li parentName="ul">{`Occupational Therapy `}</li>
      <li parentName="ul">{`Optometry `}</li>
      <li parentName="ul">{`Orthoptics`}</li>
      <li parentName="ul">{`Orthotics/Prosthetics`}</li>
      <li parentName="ul">{`Osteopathy `}</li>
      <li parentName="ul">{`Pathology`}</li>
      <li parentName="ul">{`Perfusion (Medical)`}</li>
      <li parentName="ul">{`Pharmacy`}</li>
      <li parentName="ul">{`Physiotherapy `}</li>
      <li parentName="ul">{`Podiatry `}</li>
      <li parentName="ul">{`Psychology `}</li>
      <li parentName="ul">{`Radiology`}</li>
      <li parentName="ul">{`Remedial Massage  `}</li>
      <li parentName="ul">{`Social Work `}</li>
      <li parentName="ul">{`Specialist Physician`}</li>
      <li parentName="ul">{`Specialist Treatment centres `}</li>
      <li parentName="ul">{`Speech Pathology `}</li>
    </ul>
    <p>{`Comcare is a delivered nationwide insurance program with eligible employees in all states and territories. Providers in all states and territories are welcomed to join and enable Comcare claiming. `}<br /><br /></p>
    <p>{`Providers must first register with Tyro Health prior to submitting a Comcare claim. This registration process is self-service and can be completed within a few minutes. `}</p>
    <p>{`If providers deliver services from multiple locations or businesses, each unique provider number must be registered. Details on provider registration and Comcare activation is provided at:
`}<a parentName="p" {...{
        "href": "https://help.medipass.com.au/en/articles/5740300-enable-comcare-digital-invoicing"
      }}>{`https://help.medipass.com.au/en/articles/5740300-enable-comcare-digital-invoicing`}</a></p>
    <Divider marginTop="major-4" marginBottom="major-4" mdxType="Divider" />
    <h3 {...{
      "id": "patients",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#patients",
        "aria-label": "patients permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Patients`}</h3>
    <p>{`Any injured federal government employee with a valid claim number can use this service. `}</p>
    <p>{`Though rare, patients may have more than one active case. In this event, the patient will have a unique claim number for each active case.`}</p>
    <h4 {...{
      "id": "eligibility-checks-and-quotes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#eligibility-checks-and-quotes",
        "aria-label": "eligibility checks and quotes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Eligibility checks and quotes`}</h4>
    <p>{`The Comcare  integration does not currently support patient quotes for services to be rendered. Tyro Health plans to introduce these capabilities in a future update. However,the Claim number and date of birth will be checked and validated prior to invoice submission. `}</p>
    <Divider marginTop="major-4" marginBottom="major-4" mdxType="Divider" />
    <h4 {...{
      "id": "processing-and-settlement-periods-and-payment-of-invoices",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#processing-and-settlement-periods-and-payment-of-invoices",
        "aria-label": "processing and settlement periods and payment of invoices permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Processing and Settlement periods and Payment of invoices`}</h4>
    <p>{`Providers can lodge Comcare claims 24/7.`}<br /></p>
    <p>{`Most claims are processed instantly by Comcare using their straight-through adjudication method. Those claims will return an approved or declined status within 30 seconds and the SDK will return that status via a callback. `}<br /></p>
    <p>{`Claims that cannot be instantly processed will require manual review by case managers.As Comcare has a pharmacy review panel, all pharmacy related claims will go into the under review status when submitted. Other claims which contain miscellaneous, general catch-all and special items will also go into under review status for manual review when submitted. `}</p>
    <p>{`Invoices that are placed Under Review at the time of submission will be handled manually by a Comcare claims manager. This typically occurs during business hours Monday-Friday.`}<br /></p>
    <p>{`Once the provider's invoice has been approved by Comcare within Tyro Health, funds will be deposited to your nominated bank account the following business day by Tyro Health. `}<br /></p>
    <p>{`Comcare utilises a 5pm AET end of day cutoff. Claims approved after 5pm AET will be included in the following business day settlement period. If multiple claims are settled for the same provider, a single aggregate payment will be made. A remittance report is issued to providers on settlement which details each claim paid. If a provider has multiple claims - even from different insurers - or card payments issued by Tyro Health in the same settlement period, those claims will be included in the same aggregated settlement.`}<br /></p>
    <Divider marginTop="major-4" marginBottom="major-4" mdxType="Divider" />
    <h4 {...{
      "id": "reporting-statements-and-receipts",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#reporting-statements-and-receipts",
        "aria-label": "reporting statements and receipts permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Reporting, statements and receipts`}</h4>
    <p>{`Tyro Health automatically generates patient reports, statements and receipts on behalf of the partner/providers. These reports and receipts can be obtained from Medipass provider portal or via API calls. `}</p>
    <p>{`Invoices are automatically generated by Medipass for each employee and the treatments delivered. These invoices are directly sent to Comcare's for approval. Providers can print copies of the invoice from the Medipass provider portal or invoice details can be generated by a partner PMS for print or email to patients.`}</p>
    <h4 {...{
      "id": "remittance-reports",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#remittance-reports",
        "aria-label": "remittance reports permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Remittance reports`}</h4>
    <p>{`Remittance reports are available on the connect portal Connect Portal when the payment has been made. Remittance reports will include all payments to a provider for the given settlement period which can include payments for other funding sources such as icare, Comcare and payment cards. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      